/* ================================================== *\
	Variables & Mixins
\* ================================================== */
@import "variables-site/variables-site";
@import "mixins/mixins-master";

/* ================================================== *\
	Vendors
\* ================================================== */
@import "../node_modules/bootstrap/scss/bootstrap";
@import "../node_modules/bootstrap/scss/bootstrap-grid";
@import "../node_modules/bootstrap/scss/bootstrap-reboot";
@import "../node_modules/bootstrap/scss/images";
@import "../node_modules/slick-carousel/slick/slick.scss";

/* ================================================== *\
	Normalize
\* ================================================== */
@import "normalize";

/* ================================================== *\
	Global
\* ================================================== */
@import "global/base";
@import "global/typography";
@import "global/navigation";


/* ================================================== *\
	Modules
\* ================================================== */
@import "modules/cookie";
@import "modules/hero";
@import "modules/panel";
@import "modules/contact";

/* ================================================== *\
	Custom Media
\* ================================================== */
@import "global/media";