#panel {
	background-image:url(../resources/images/green-bg.png);
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
	padding: 120px 0;

	p {
		font-size: 2.8rem;
		line-height: 4rem;
		color: white;

		@include media-breakpoint-down(lg) {
			text-align: center;
		}

		@include media-breakpoint-down(md) {
			font-size: 2rem;
			line-height: 3rem;
			text-align: center;
		}
	}
}