#hero {
	width: 100%;
	height: 100vh;
	overflow: hidden;
	position: relative;

	.hero-item {
		position: relative;
		width: 100%;
		height: 100vh;
	}

	img {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		width: 100%;
		height: 100%;
		object-position: center;
		object-fit: cover;
	}

	.slick-arrow {
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		height: 60px;
		width: 60px;
		background: white;
		text-indent: -999999px;
		border: none;
		outline: 0;
		box-shadow: none;
		z-index: 10;
		background-image: url(../resources/images/arrow.svg);
		background-size: 46px;
		background-position: center;
		background-repeat: no-repeat;


		@include media-breakpoint-down(sm) {
			height: 50px;
			width: 50px;
			background-size: 40px;
			margin-top: -42px;
		}

		&.slick-prev {
			left: 0;
			transform: translateY(-50%) rotate(180deg);
		}

		&.slick-next {
			right: 0;
		}
	}
}