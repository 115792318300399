#contact {
	background: $grey;
	color: $dark;
	padding: 80px 0;

	p,
	h4 {
		color: $dark;
	}

	a {
		color: $dark;

		&:visited {
			color: $dark;
		}
	}

	&-nav {
		padding: 0;
		margin: 0;
		font-size: 0;

		li {
			display: inline-block;
			position: relative;
			padding-right: 30px;
			margin-right: 20px;

			@include media-breakpoint-down(sm) {
				padding-right: 20px;
				margin-right: 10px;
			}

			&:after {
				content:'/';
				display: block;
				font-size: 2rem;
				line-height: 2rem;
				position: absolute;
				right: 0;
				top: 0;
				color: $dark;

			}

			&:last-child {
				margin-right:0;
				padding-right: 0;
				&:after {
					content: none;
				}
			}
		}

		button {
			outline: 0;
			opacity: 1;
			background: none;
			box-shadow: none;
			border: none;
			font-family: $cpb;
			font-size: 2rem;
			line-height: 2rem;
			text-transform: uppercase;
			position: relative;
			margin-bottom: 0px;

	

			@include media-breakpoint-up(lg) {
				margin-bottom: 50px;
			}

			@include media-breakpoint-down(md) {
				font-size: 1.6rem;
				line-height: 1.6rem;

			}

			@include media-breakpoint-down(sm) {
				font-size: 1.4rem;
				line-height: 1.4rem;
				margin-bottom: 20px;
				
			}

			@include media-breakpoint-up(sm) {
				margin-bottom: 40px;
				
			}

			

			&.active {
				opacity: 1;
				color: white;

				&:after {
					content:'';
					position: absolute;
					width: 100%;
					height: 4px;
					background: $dark;
					left:0;
					right:0;
					bottom: -50px;

					@include media-breakpoint-down(md) {
						bottom: -40px;
					}

					@include media-breakpoint-down(xs) {
						content: none;
					}
				}
			}
		}
	}

	&-content {
		border-top: 1px solid $dark;
		border-bottom: 1px solid $dark;
		padding-top: 60px;
		min-height: 388px;

		@include media-breakpoint-down(md) {
			padding-bottom:40px;
		}
	}
}

.logo-build {
	max-width: 120px;

	&--alt {
		@include media-breakpoint-down(md) {
			margin-top:60px;
		}
	}
}

.contact {
	&-details {
		margin: 0 0 60px 0;
		padding: 0;
		list-style: none;
		text-align: left;
		color: white;

		@include media-breakpoint-down(lg) {
			margin-top: 30px;
		}

		@include media-breakpoint-down(md) {
			font-size:1.6rem;
			line-height:2.2rem;
			margin-bottom: 20px;
		}

		li {
			@include media-breakpoint-down(xl) {
				font-size:1.5rem;
				line-height: 2.8rem;
			}
		}


		a {

			@include media-breakpoint-down(xl) {
				font-size:1.5rem;
				line-height: 2.8rem;
			}

		}		

	}
}

