p {
	font-family: $main;
	font-style: normal;
	font-weight: 500;
	color: $grey;
	font-size: 2rem;
	line-height: 3.2rem;
	text-align: left;
	margin-bottom: 0;
	letter-spacing:-0.8px;

	strong {
		font-weight: 700;
	}

	@include media-breakpoint-down(md) {
		font-size: 3rem;
		line-height: 4.6rem;
	}

	@include media-breakpoint-down(sm) {
		font-size: 1.8rem;
		line-height: 3.2rem;
		text-align: center;
	}
}

.details {
	font-size:3.2rem;
	line-height:5.6rem;
	color:white;
	margin:0 0 90px;
	padding:0;
	font-family:$cpb;
	font-weight:normal;
	@include media-breakpoint-down(md) {
		font-size:2.6rem;
		line-height:3.2rem;
		margin:0 0 60px;
	}

	@include media-breakpoint-down(sm) {
		font-size:1.8rem;
		line-height:2.8rem;
		margin:0 0 40px;
	}
}
