a {
	color: $grey;
	border-bottom: 1px solid transparent;
	text-decoration: none;
	transition-duration: 0.3s;
    transition-property: all;
	
	&:visited {
		color:black;
	}

	&:hover,
	&:active {
		color: $grey;
		border-color: $grey;
		text-decoration: none;
	}
}

.btn {
	font-family: $cpb;
	background: $dark;
	color: $grey;
	padding: 5px 24px;
	border-radius: 50px;
	font-size: 2rem;
	line-height: 4.8rem;
	letter-spacing: 0.05em;
	text-transform: uppercase;
	border: none;
	transition-duration: 0.3s;
    transition-property: all;

	&:visited {
		color: $grey;
	}

	&:hover,
	&:active {
		background: white;
		color: $dark;
	}

	@include media-breakpoint-down(md) {
		font-size: 1.6rem;
		line-height: 2.8rem;
		padding:10px 24px;
	}
}