#footer-wrapper {
	background: $navy;
	color: $grey;
	padding:20px 0;

	@include media-breakpoint-down(xs) {
		padding:30px 0;
	}
}

#mandg {
	max-width:140px;

	@include media-breakpoint-down(xs) {
		max-width:90px;
		margin-bottom:10px; 
	}

}

#core {
	max-width:165px;

	@include media-breakpoint-down(xs) {
		max-width:90px;
	}

	
}

#copyright {
	color:white;
	opacity: 0.5;
	font-family:$cpl;
	text-align: right;
	text-transform: uppercase;

	@include media-breakpoint-down(md) {
		margin-top:20px;
		text-align: center;
		font-size:1.4rem;
		line-height:1.4rem;
		padding-bottom:0;
		margin-bottom: 0;
	}
}