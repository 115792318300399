html {
	box-sizing: border-box;
	min-height:100%;
    height:100%;
    font-size:62.5%;
	line-height:62.5%;
	margin:0;
    padding:0;
}

*,
*:before,
*:after { 
	box-sizing: inherit;
}

@include scrollbars(.5em, slategray);

body {
	background:$grey;
	min-height: 100%;
	height: 100%;
	font-size: 2rem;
	line-height: 3.2rem;
	font-family: $main;
	font-weight: 500;
	font-style: normal;
	color: $dark;
	margin: 0;
	padding: 0;
	text-rendering: optimizeLegibility !important;
	-webkit-font-smoothing: antialiased !important;

}



hr {

	border: 0;
	height: 1px;
	margin-bottom: 1.5em;
}

img {
	height: auto; 
	max-width: 100%; 
	width:100%;
}

ol {
	padding-bottom:30px;
}

figure {
	margin:0;
	padding:0;
}

#background {
	max-width:100%;
	width:100%;
	background-image:url(../resources/images/fitzrovia-pattern.svg);
	background-repeat:no-repeat;
	background-size:auto 100%;
	background-position:0 0;

	&:before {
		content:'';
		display:block;
		position:absolute;
		top:0;
		left:0;
		width:100%;
		height:50%;
		background:#192341;
		z-index: -1;
	}

	@include media-breakpoint-down(md) {
		background-image:none;
	}
}



#content-wrapper {
	min-height:5000px;
}


//Padding
.o-pd-top-ss {
	padding-top:5px;
}

.o-pd-top-xs {
	padding-top:20px;
}

.o-pd-top-sm {
	padding-top:40px;

}

.o-pd-top-md {
	padding-top: 60px;
}

.o-pd-top-lg {
	padding-top:120px;
}

.o-pd-btm-ss {
	padding-bottom:5px;
}

.o-pd-btm-xs {
	padding-bottom:20px;
}

.o-pd-btm-sm {
	padding-bottom:40px;

}

.o-pd-btm-md {
	padding-bottom: 60px;
}

.o-pd-btm-lg {
	padding-bottom: 120px;
}

.p-l-50 {
	padding-left:50px;
}

.p-r-50 {
	padding-right:50px;
}

//Margin
.o-mg-none {
	margin:0;
}

.o-mg-top-none {
	margin-top:0px;
}

.o-mg-top-ss {
	margin-top:5px;
}

.o-mg-top-xs {
	margin-top:20px;
}

.o-mg-top-sm {
	margin-top:40px;
}

.o-mg-top-md {
	margin-top: 60px;
}

.o-mg-top-lg {
	margin-top:120px;
}


.o-mg-btm-ss {
	margin-bottom:8px;
}

.o-mg-btm-xs {
	margin-bottom:20px;
}

.o-mg-btm-sm {
	margin-bottom:40px;
}

.o-mg-btm-md {
	margin-bottom: 60px;
}

.o-mg-btm-lg {
	margin-bottom:120px;
}

//Animations
.js-fade-in-left {
	opacity: 0;
	transform: translate(-100px, 0px); 
  	transition: all 0.5s ease-out 0s;
}

.js-fade-in-right {
	opacity: 0;
	transform: translate(20px, 0px); 
  	transition: all 0.5s ease-out 0s;
}

.js-fade-in-down {
	opacity: 0;
	transform: translate(0px, -20px); 
  	transition: all 0.5s ease-out 0s;
}


.js-fade-in-up {
	opacity: 0;
	transform: translate(0px, 20px); 
  	transition: all 0.7s ease-out 0s;
}

.js-fade-in {
	opacity: 0;
	transition: all 0.5s ease-out 0s;
}

.o-delay-lg {
	transition-delay:1.6s;
}

.o-delay-md {
	transition-delay:1.2s;
}

.o-delay-sm {
	transition-delay:0.8s;
}

.o-delay-xs {
	transition-delay:0.3s;
}

.o-delay-ss {
	transition-delay:0.1s;
}


.o-fade-in-up {
  opacity: 1;
  transform: translate(0px, 0px); 
}

.o-fade-in-down {
  opacity: 1;
  transform: translate(0px, 0px); 
}

.o-fade-in {
  opacity: 1;
}

