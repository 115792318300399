h1, h2, h3, h4, h5, h6 {
	clear: both;
}

h1 {
	font-family:$alt;
	color:$dark;
	font-size:6.8rem;
	line-height:7.2rem;
	text-align: left;
	margin:0;

	@include media-breakpoint-down(xl) {
		font-size: 5.8rem;
		line-height: 6.4rem;
	}

	@include media-breakpoint-down(lg) {
		font-size: 5rem;
		line-height: 6rem;
		margin-bottom:40px;
		text-align: center;
	}


	@include media-breakpoint-down(md) {
		font-size: 4rem;
		line-height: 4.8rem;
		text-align: center;
	}
}

h2 {
	font-family:$alt;
	color: white;
	font-size:6.5rem;
	line-height:7rem;
	text-align: left;
	letter-spacing: -0.02em;
	margin:0 0 72px 0;

	@include media-breakpoint-down(xl) {
		font-size: 5.8rem;
		line-height: 6.4rem;
	}

	@include media-breakpoint-down(lg) {
		font-size: 5rem;
		line-height: 6rem;
		margin-bottom: 40px;
		text-align: center;
	}

	@include media-breakpoint-down(md) {
		font-size: 4rem;
		line-height: 4.8rem;
		text-align: center;
	}
}


h3 {
	font-size:4.8rem;
	line-height:5.2rem;
	color:black;
	margin::0;
	text-align: left;
	font-family:$alt;

	@include media-breakpoint-down(md) {
		margin-bottom:37px;
	}
}

h4 {
	color: $grey;;
	font-size:2rem;
	line-height:3.2rem;
	font-family:$cpb;
	text-align: left;
}