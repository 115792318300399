/*--------------------------------------------------------------
## Links
--------------------------------------------------------------*/
@import "links";

#top-wrapper {
	position: fixed;
	width: 100%;
	top: 0;
	background: $grey;
	z-index: 9999;
	padding: 40px 0;
	text-align: center;
	box-shadow: 0px 0 8px rgba(0,0,0,0.1);

	@include media-breakpoint-down(xl) {
		padding: 30px;
	}

	@include media-breakpoint-down(lg) {
		padding: 20px;
	}
}

#logo {
	max-width: 315px;
	height: auto;
	margin: 0 auto;

	@include media-breakpoint-down(xl) {
		max-width: 270px;
	}

	@include media-breakpoint-down(lg) {
		max-width: 250px;
	}

	@include media-breakpoint-down(md) {
		max-width: 220px;
	}

	@include media-breakpoint-down(sm) {
		max-width: 180px;
	}
}

/*--------------------------------------------------------------
## Footer
--------------------------------------------------------------*/
@import "footer";